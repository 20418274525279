import IconLoader from "@/components/IconLoader";
import i18n from "@/services/i18n";
import { convertPxToRem } from "@/utils";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";
import "./animations.css";

interface HealthCheckAnimatedProps {
  onClick: () => void;
  shouldAnimate?: boolean;
}

export default function HealthCheckAnimated({
  onClick,
  shouldAnimate = true,
}: HealthCheckAnimatedProps) {
  const { t } = useTranslation();

  function onNextButtonClick() {
    onClick();
  }

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%" }}>
      <Stack
        alignItems="center"
        bgcolor="accent1.main"
        sx={{
          clipPath: "ellipse(200% 57% at 15% 42%)",
          height: "60%",
          maxHeight: "400px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <IconLoader
          icon="Pattern4"
          color="info"
          sx={{
            fontSize: convertPxToRem(310),
            position: "relative",
            top: convertPxToRem(140),
            left: (theme) => theme.spacing(1),
          }}
        />
        <Box
          src={`/components/CVPFlow/${i18n.resolvedLanguage}/HealthCheckAnimated/HealthCheck.png`}
          component="img"
          loading="eager"
          sx={{
            zIndex: 2,
            width: "280px",
            position: "absolute",
            top: convertPxToRem(40),
            ...(shouldAnimate === true && {
              animation: "slideUpAndFadeIn 1s ease-in-out forwards",
            }),
          }}
          alt={t("CVPFlow.screens.HealthCheck.animationAlternateText")}
        />
      </Stack>

      <Stack p={2} spacing={4}>
        <Typography variant="h1" textAlign="center">
          {t("CVPFlow.screens.HealthCheck.title")}
        </Typography>
        <ButtonWithAnalytics
          text={t("common.nextButton")}
          page="Health check CVP"
          intent="navigational"
          onClick={onNextButtonClick}
        >
          {t("common.nextButton")}
        </ButtonWithAnalytics>
      </Stack>
    </Stack>
  );
}
